import { fetchAPI } from '@utils';
import {
  DoctorOffScheduleListType,
  PharmacistWorkingTimeType,
} from 'types/userInformationType';
import * as MyDoctorApiType from './types/index.d';

const MY_DOCTOR_TYPE = 'myDoctor';

export const myDoctorAPI = {
  // 약사어드민 조제취소
  postCancelTP(params: { cancel_reason: string }, id: number) {
    return fetchAPI.post(
      `telepharmacy/pharmacist/${id}/ph_cancel/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },

  // rest-auth
  postLoginEmail(params: object) {
    return fetchAPI.post('rest-auth/login/', params, MY_DOCTOR_TYPE);
  },
  postPasswordChange(params: object) {
    return fetchAPI.post('rest-auth/password/change/', params, MY_DOCTOR_TYPE);
  },

  // user

  postVerifyAuthentication({
    token,
    rejected,
  }: {
    token: string;
    rejected?: boolean;
  }) {
    return fetchAPI.post(
      'user/medical_auth/authenticate/',
      {
        token,
        ...(rejected && { rejected }),
      },
      MY_DOCTOR_TYPE,
    );
  },

  getVerifyAuthentication({ token }: { token: string }) {
    return fetchAPI.get(
      `user/medical_auth/get-by-token/`,
      {
        token,
      },
      MY_DOCTOR_TYPE,
    );
  },

  postVerifyAuthenticateFromMobile(params: object) {
    return fetchAPI.post('user/medical_auth/login/', params, MY_DOCTOR_TYPE);
  },

  postUserAuthenticate(params: object) {
    return fetchAPI.post(
      'user/medical_auth/create-auth-token/',
      params,
      MY_DOCTOR_TYPE,
    );
  },

  postFCMLogin(params: object) {
    return fetchAPI.post('user/login/', params, MY_DOCTOR_TYPE);
  },
  postSendMarketingFCM(params: object) {
    return fetchAPI.post(
      'user/send/send_marketing_fcm/',
      params,
      MY_DOCTOR_TYPE,
    );
  },
  getSendReceivers(params: object) {
    return fetchAPI.get('user/send/get_receivers/', params, MY_DOCTOR_TYPE);
  },

  // doctor
  getDoctorData(params: object, userID: number) {
    return fetchAPI.get(`doctor/${userID}/`, params, MY_DOCTOR_TYPE);
  },

  // doctor-info
  patchDoctorInfoWorkingTime(params: object, id: number) {
    return fetchAPI.patch(
      `doctor-info/working-time/${id}/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  getDoctorInfoOffSchedule(params: object): Promise<DoctorOffScheduleListType> {
    return fetchAPI.get(`doctor-info/off-schedule/`, params, MY_DOCTOR_TYPE);
  },

  postDoctorInfoOffSchedule(params: object) {
    return fetchAPI.post(`doctor-info/off-schedule/`, params, MY_DOCTOR_TYPE);
  },
  patchDoctorInfoOffSchedule(params: object, id: number) {
    return fetchAPI.patch(
      `doctor-info/off-schedule/${id}/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },

  // doctor-api
  getTelemedicineCount(): Promise<MyDoctorApiType.TelemedicineCountReturnType> {
    return fetchAPI.get(
      'doctor-api/telemedicine/telemedicine/count/',
      {},
      MY_DOCTOR_TYPE,
    );
  },
  getTelemedicineTelemedicineList(
    params: MyDoctorApiType.TelemedicineListParameterType,
  ): Promise<MyDoctorApiType.TelemedicineListReturnType> {
    return fetchAPI.get(
      'doctor-api/telemedicine/telemedicine/',
      params,
      MY_DOCTOR_TYPE,
    );
  },
  getTelemedicineTelemedicinePrescriptions(
    tm_id: number,
  ): Promise<MyDoctorApiType.TelemedicinePrescriptionsType> {
    return fetchAPI.get(
      `doctor-api/telemedicine/telemedicine/${tm_id}/prescriptions/`,
      {},
      MY_DOCTOR_TYPE,
    );
  },
  getHealthInformation(
    tm_id: number,
  ): Promise<MyDoctorApiType.GetHealthInformationReturnType> {
    return fetchAPI.get(
      `doctor-api/telemedicine/telemedicine/${tm_id}/health-info/`,
      {},
      MY_DOCTOR_TYPE,
    );
  },
  postHealthCareChallengeDoctorRequest(
    params: MyDoctorApiType.PostHealthCareChallengeDoctorRequestParameterType,
  ): Promise<MyDoctorApiType.PostHealthCareChallengeDoctorRequestReturnType> {
    return fetchAPI.post(
      `doctor-api/health-care/challenge/doctor-request/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postPrescription(
    params: MyDoctorApiType.PostPrescriptionParameterType,
  ): Promise<MyDoctorApiType.PostPrescriptionReturnType> {
    return fetchAPI.post(
      'doctor-api/prescription/prescription/',
      params,
      MY_DOCTOR_TYPE,
    );
  },
  getTmCancelReason(): Promise<MyDoctorApiType.GetCancelReasonReturnType> {
    return fetchAPI.get(
      `doctor-api/telemedicine/cancel-reason/`,
      {},
      MY_DOCTOR_TYPE,
    );
  },

  // pharmacist
  async getPharmacistData(params: object, userID: number) {
    return fetchAPI.get(
      `pharmacist/pharmacist/${userID}/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },

  // getPharmacyWorkingTime(params: object, userID: Number) {
  //   return fetchAPI.get(
  //     `pharmacist/pharmacist/${userID}/`,
  //     params,
  //     MY_DOCTOR_TYPE,
  //   );
  // },

  patchPharmacistData(
    params: {
      nickname: string;
      pharmacist_working_time: PharmacistWorkingTimeType;
    },
    profile: number,
  ): Promise<PharmacistWorkingTimeType> {
    return fetchAPI.patch(
      `pharmacist/pharmacist/${profile}/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },

  // pharmacist-api
  changePharmacyWorkingTime(id: number, params: PharmacistWorkingTimeType) {
    return fetchAPI.patch(
      `pharmacist-api/pharmacist/pharmacy/working-time/${id}/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },

  getPharmacyWorkingTime(id: number) {
    return fetchAPI.get(
      `pharmacist-api/pharmacist/pharmacy/${id}/working-time/`,
      {},
      MY_DOCTOR_TYPE,
    );
  },

  getTelepharmacyCount(): Promise<MyDoctorApiType.TelepharmacyCountReturnType> {
    return fetchAPI.get(
      'pharmacist-api/telepharmacy/tpprocess/count/',
      {},
      MY_DOCTOR_TYPE,
    );
  },
  getTelepharmacyTpprocessList(
    params: MyDoctorApiType.TelepharmacyListParameterType,
  ): Promise<MyDoctorApiType.TelepharmacyListReturnType> {
    return fetchAPI.get(
      'pharmacist-api/telepharmacy/tpprocess/',
      params,
      MY_DOCTOR_TYPE,
    );
  },
  getTelepharmacyTpprocessPrescriptions(
    tpprocess_id: number,
  ): Promise<MyDoctorApiType.TelepharmacyPrescriptionsType> {
    return fetchAPI.get(
      `pharmacist-api/telepharmacy/tpprocess/${tpprocess_id}/prescriptions/`,
      {},
      MY_DOCTOR_TYPE,
    );
  },
  getTpCancelReason(): Promise<MyDoctorApiType.GetCancelReasonReturnType> {
    return fetchAPI.get(
      `pharmacist-api/telepharmacy/cancel-reason/`,
      {},
      MY_DOCTOR_TYPE,
    );
  },

  // telemedicine
  getTelemedicineItem(params: object, id: number) {
    return fetchAPI.get(`telemedicine/doctor/${id}/`, params, MY_DOCTOR_TYPE);
  },
  getTelemedicineListHospital(params: object) {
    return fetchAPI.get('telemedicine/hospital/', params, MY_DOCTOR_TYPE);
  },
  getTelemedicineTmnoteList(params: object) {
    return fetchAPI.get('telemedicine/tmnote/', params, MY_DOCTOR_TYPE);
  },
  postAcceptTelemedicine(params: object, id: number) {
    return fetchAPI.post(
      `telemedicine/doctor/${id}/accept/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postCancelTelemedicine(params: object, id: number) {
    return fetchAPI.post(
      `telemedicine/doctor/${id}/cancel/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postRejectTelemedicine(params: object, id: number) {
    return fetchAPI.post(
      `telemedicine/doctor/${id}/reject/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postFinishTelemedicine(
    params: object,
    id: number,
    type: 'multipart/form-data' | 'application/json',
  ) {
    return fetchAPI.post(
      `telemedicine/doctor/${id}/finish/`,
      params,
      MY_DOCTOR_TYPE,
      type,
    );
  },
  postChangeTimeTelemedicine(params: object, id: number) {
    return fetchAPI.post(
      `telemedicine/doctor/${id}/change_time/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postTelemedicineTmnote(params: object) {
    return fetchAPI.post(`telemedicine/tmnote/`, params, MY_DOCTOR_TYPE);
  },
  deleteTelemedicineTmnote(params: object, id: number) {
    return fetchAPI.delete(
      `telemedicine/tmnote/${id}/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },

  // telepharmacy
  getTelepharmacyItem(params: object, id: number) {
    return fetchAPI.get(
      `telepharmacy/pharmacist/${id}/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postRejectTelepharmacy(params: object, id: number) {
    return fetchAPI.post(
      `telepharmacy/pharmacist/${id}/reject/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postFinishTelepharmacy(params: object, id: number) {
    return fetchAPI.post(
      `telepharmacy/pharmacist/${id}/finish/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postAcceptTelepharmacy(params: object, id: number) {
    return fetchAPI.post(
      `telepharmacy/pharmacist/${id}/accept/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postCancelTelepharmacy(params: object, id: number) {
    return fetchAPI.post(
      `telepharmacy/pharmacist/${id}/ph_cancel/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },

  // callcs
  postCallCS(params: object) {
    return fetchAPI.post(`callcs/`, params, MY_DOCTOR_TYPE);
  },

  // symptomkeyword
  getSymptomKeywordList(params: object) {
    return fetchAPI.get('symptomkeyword/', params, MY_DOCTOR_TYPE);
  },

  // medicine_guide
  postMedicineGuide(params: object) {
    return fetchAPI.post('medicine_guide/', params, MY_DOCTOR_TYPE);
  },
  getMedicineGuide(params: object, id: number) {
    return fetchAPI.get(`medicine_guide/${id}/`, params, MY_DOCTOR_TYPE);
  },
  patchMedicineGuide(params: object, id: number) {
    return fetchAPI.patch(`medicine_guide/${id}/`, params, MY_DOCTOR_TYPE);
  },
  putMedicineGuide(params: object, id: number) {
    return fetchAPI.put(`medicine_guide/${id}/`, params, MY_DOCTOR_TYPE);
  },

  // file
  getPresignedData(
    params: MyDoctorApiType.GetPresignedDataParameterType,
  ): Promise<MyDoctorApiType.GetPresignedDataReturnType> {
    return fetchAPI.get(`file/`, params, MY_DOCTOR_TYPE);
  },

  getManagementMedicineList(
    param: object,
    id: number,
  ): Promise<MyDoctorApiType.ManagedMedicineListDataType> {
    return fetchAPI.get(
      `pharmacist-api/pharmacist/pharmacy/${id}/medicines/`,
      param,
      MY_DOCTOR_TYPE,
    );
  },
  postManagementMedicineList(
    params: object[],
    // TODO 타입 가드 params: MyDoctorApiType.PostManagedMedicineParamType[],
  ) {
    return fetchAPI.post(
      `pharmacist-api/pharmacist/pharmacy-medicines/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  postManagementMedicineListV2(
    params: MyDoctorApiType.PostManagementMedicineListV2Type,
  ) {
    return fetchAPI.post(
      `pharmacist-api/pharmacist/v2/pharmacy-medicines/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  patchManagementMedicine(
    params: MyDoctorApiType.PatchManagedMedicineParamType,
  ) {
    return fetchAPI.patch(
      `pharmacist-api/pharmacist/pharmacy-medicines/${params.id}/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  putManagementUnitMedicine(
    params: MyDoctorApiType.PutManagedUnitMedicineParamType,
  ) {
    return fetchAPI.put(
      `pharmacist-api/pharmacist/v2/pharmacy-medicines/${params.id}/`,
      params,
      MY_DOCTOR_TYPE,
    );
  },
  getPharmacyAutoConfirmed(
    id: number,
  ): Promise<MyDoctorApiType.PharmacyAutoConfirmedDataType> {
    return fetchAPI.get(
      `pharmacist-api/pharmacist/pharmacy/${id}/auto-confirmed/`,
      {},
      MY_DOCTOR_TYPE,
    );
  },
  patchPharmacyAutoConfirmed(
    params: MyDoctorApiType.PatchPharmacyAutoConfirmedParamType,
  ) {
    return fetchAPI.patch(
      `pharmacist-api/pharmacist/pharmacy/${params.id}/update-auto-confirmed/`,
      { auto_confirmed: params.auto_confirmed },
      MY_DOCTOR_TYPE,
    );
  },
  getPrescriptionDetailList(
    params: MyDoctorApiType.GetPrescriptionDetailParams,
  ): Promise<MyDoctorApiType.GetPrescriptionDetailResponse> {
    return fetchAPI.get(
      `pharmacist-api/pharmacist/prescription/${params.prescriptionId}/`,
      { tm_id: params.tmId, prescription_id: params.prescriptionId },
      MY_DOCTOR_TYPE,
    );
  },
  getConfig(
    params: MyDoctorApiType.GetConfigureType,
  ): Promise<MyDoctorApiType.GetConfigureReturnType> {
    return fetchAPI.get(
      `patient-api/app/configure/${params?.key}/`,
      {},
      'myDoctor',
    );
  },
};
