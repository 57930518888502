import { myDoctorAPI } from '@services/myDoctor';
import { isNil } from 'lodash';
import { useQuery } from 'react-query';

interface UseFetchPrescriptionDetailListProps {
  tmId: number | undefined;
  prescriptionId: number | undefined;
}

const useFetchPrescriptionDetailList = ({
  tmId,
  prescriptionId,
}: UseFetchPrescriptionDetailListProps) => {
  return useQuery({
    queryFn: () =>
      myDoctorAPI.getPrescriptionDetailList({
        tmId: tmId || 0,
        prescriptionId: String(prescriptionId),
      }),
    queryKey: [`TP_PRESCRIPTION_${prescriptionId}`],
    enabled: !isNil(prescriptionId) && !isNil(tmId),
  });
};

export default useFetchPrescriptionDetailList;
