import styled from 'styled-components';

export const NotificationMethodButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const ChevronDownIconArea = styled.div`
  rotate: 270deg;
`;
