import { Button, COLOR, Font } from '@components/atoms';
import BFlex from '@components/meraki-ui/BFlex';
import CopyCompleteToast from '@components/organisms/toast/copyCompleteToast';
import { handleCopyClipBoard } from '@utils/clipboard';
import { ReactNode, useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Style from './index.style';

export interface IReservationInformationItem {
  id: number;
  title: string;
  content?: () => ReactNode;
  text?: string;
  iconURL: any;
  canCopy: boolean;
}

interface ReservationInformationItemProps {
  reservationInformationItem: IReservationInformationItem;
}

function ReservationInformationItem({
  reservationInformationItem,
}: ReservationInformationItemProps) {
  const onClick = useCallback(async () => {
    try {
      await handleCopyClipBoard(reservationInformationItem?.text ?? '');
      toast(<CopyCompleteToast />, { position: 'top-center' });
    } catch (error) {
      console.error(error);
    }
  }, [reservationInformationItem?.text]);

  return (
    <Style.ContentBodyContainer key={reservationInformationItem.id}>
      <Style.ContentBodyTitleArea>
        <Style.ContentBodyTitle>
          <Font fontType="body1" color="fill/medium">
            {reservationInformationItem.title}
          </Font>
        </Style.ContentBodyTitle>
      </Style.ContentBodyTitleArea>
      <Style.ContentBodyTextArea>
        <Style.ContentBodyText>
          {reservationInformationItem.content ? (
            reservationInformationItem.content()
          ) : (
            <Font fontType="body1_medium" color="fill/dark">
              {reservationInformationItem.text || '-'}
            </Font>
          )}
          {reservationInformationItem.iconURL ? (
            <Style.ContentBodyTextIcon
              src={reservationInformationItem.iconURL}
              alt={reservationInformationItem.text}
            />
          ) : null}
          {reservationInformationItem.canCopy ? (
            <BFlex>
              <Button
                title={
                  <Font fontType="body2" color="fill/dark">
                    복사
                  </Font>
                }
                width="50px"
                height="32px"
                padding="5px 12px"
                onClick={onClick}
                backgroundColor={COLOR['fill/white']}
                borderColor={COLOR['border/outline']}
                borderRadius="6px"
              />
            </BFlex>
          ) : undefined}
        </Style.ContentBodyText>
      </Style.ContentBodyTextArea>
    </Style.ContentBodyContainer>
  );
}

export default ReservationInformationItem;
