import { loadingSpinner } from '@components/atoms/lottie';
import { commonHooks } from '@hooks';
import { useAppDispatch } from '@stores/hooks';
import { getTelepharmacyList_finished_quick } from '@stores/telepharmacyStore/telepharmacyList';
import Lottie from 'lottie-react';
import { ReactNode, useEffect, useState } from 'react';
import { TelepharmacyListItemModelType } from 'types/myDoctorModelType/telepharmacyModelType';
import ListContent from './components/listContent';
import Pagination from './components/pagination';
import * as Style from './index.style';

interface TableHeaderItem {
  flex: number | string;
  title: string;
}

interface TableProps {
  tableHeaderItems: TableHeaderItem[];
  telepharmacyCount: number;
  telepharmacyData: TelepharmacyListItemModelType[] | undefined;
  renderTableBodyItem: (item: TelepharmacyListItemModelType) => ReactNode;
}

function TelepharmacyTable({
  tableHeaderItems,
  telepharmacyCount,
  telepharmacyData,
  renderTableBodyItem,
}: TableProps) {
  const { useQuery } = commonHooks;
  const query = useQuery();

  const dispatch = useAppDispatch();

  const [limit, setLimit] = useState<number>(30);
  const [isLoading, setIsLoading] = useState(true);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  useEffect(() => {
    setIsLoading(true);
    // TODO 추후 페이지네이션 고치면 여기도 수정 필요(각각의 list 가져오는 함수로 추상화)
    dispatch(
      getTelepharmacyList_finished_quick({
        offset: page * limit,
        limit,
      }),
    ).then(() => {
      setIsLoading(false);
    });
    return () => setIsLoading(false);
  }, [dispatch, page, limit]);

  return (
    <>
      <Style.ListContainer>
        <Style.ListHeaderItem>
          {tableHeaderItems.map((item, index) => {
            return (
              <ListContent.Bold key={index} flex={item.flex} border="none">
                {item.title}
              </ListContent.Bold>
            );
          })}
        </Style.ListHeaderItem>
        {isLoading ? (
          <Style.LoaderArea>
            <Lottie
              animationData={loadingSpinner}
              style={{ width: 48, aspectRatio: 1 }}
              loop
            />
          </Style.LoaderArea>
        ) : (
          telepharmacyData?.map((item) => {
            return renderTableBodyItem(item);
          })
        )}
      </Style.ListContainer>
      <Pagination page={page} maxPage={maxPage} limit={limit} />
    </>
  );
}

export default TelepharmacyTable;
