import { Font } from '@components/atoms';
import BFlex from '@components/meraki-ui/BFlex';
import { commonHooks } from '@hooks';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getTelepharmacyList_confirmed,
  selectTelepharmacyCount_confirmed,
  selectTelepharmacyList_confirmed,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { trackWaitForDispense } from '@utils/mixpanel/waitForDispense';
import { useEffect, useState } from 'react';
import AcceptedTelepharmacyCarousel from '../acceptedTelepharmacyTable/AcceptedTelepharmacyCarousel';
import TelepharmacyTable from '../telepharmacyTable';
import * as Style from './index.style';
import { ListItem } from './sections';

function isNill(v: any) {
  return !!(v === undefined || v === null);
}

export const LISTITEM_FLEX = [
  { flex: 120, title: '환자 대기시간' },
  { flex: 100, title: '수령 방법' },
  { flex: 112, title: '대체조제' },
  { flex: 160, title: '환자명(예약번호)' },
  { flex: 140, title: '연락처' },
  { flex: 120, title: '약제비' },
  { flex: 206, title: '처방전' },
];

function ConfirmedTelepharmacyTable() {
  const dispatch = useAppDispatch();

  const { useQuery, useWindowSize } = commonHooks;
  const query = useQuery();

  const [isLoading, setIsLoading] = useState(true);
  const telepharmacyData = useAppSelector(selectTelepharmacyList_confirmed);
  const telepharmacyCount = useAppSelector(selectTelepharmacyCount_confirmed);
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);

  // 필터
  const [limit, setLimit] = useState<number>(30);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getTelepharmacyList_confirmed({
        offset: page * limit,
        limit,
      }),
    ).then(() => {
      setIsLoading(false);
    });
    return () => setIsLoading(false);
  }, [dispatch, page, limit]);

  useEffect(() => {
    if (pharmacist && pharmacist.pharmacy_id) {
      trackWaitForDispense.pageTPConfirmedList({
        listCount: telepharmacyCount,
        pharmacyId: String(pharmacist.pharmacy_id),
      });
    }
  }, [pharmacist, telepharmacyCount]);

  return (
    <Style.Container>
      <Style.ListArea>
        <Style.TitleArea>
          <Font fontType="display2" color="fill/black">
            조제대기
            {!isNill(telepharmacyCount) ? `(총 ${telepharmacyCount}건)` : ''}
          </Font>
        </Style.TitleArea>

        {!isNill(telepharmacyCount) ? (
          <TelepharmacyTable
            tableHeaderItems={LISTITEM_FLEX}
            telepharmacyCount={telepharmacyCount}
            telepharmacyData={telepharmacyData}
            renderTableBodyItem={(item) => (
              <ListItem key={item.id} item={item} />
            )}
          />
        ) : (
          <Style.EmptyContainer>
            <Font fontType="body2" color="fill/dark">
              대기중인 조제 내역이 없습니다.
            </Font>
          </Style.EmptyContainer>
        )}
      </Style.ListArea>

      <BFlex width="1024px" padding="1.25rem 0 0 0">
        <AcceptedTelepharmacyCarousel pageType="confirmedTreatmentPickup" />
      </BFlex>
    </Style.Container>
  );
}

export default ConfirmedTelepharmacyTable;
