import { Blank } from '@components/atoms';
import PdfViewer from '@components/atoms/\bpdfViewer';
import OcrResultTable from '@components/organisms/ocrResultTable';
import PrescriptionItemHeader from '@components/organisms/telepharmacyDetailInformation/sections/prescriptionItem/PrescriptionItemHeader';
import { TelepharmacyType } from 'types/index';
import { PageStatus } from '../..';
import * as Style from './index.style';

export type PdfPositionType = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

const initialPdfPosition: PdfPositionType = {
  x1: 0,
  x2: 0,
  y1: 0,
  y2: 0,
};

type TelepharmacyItemType = TelepharmacyType.TelepharmacyItemType;

type PrescriptionPartItemType = {
  item: TelepharmacyItemType;
  pageType: PageStatus;
};

function PrescriptionItem({ item, pageType }: PrescriptionPartItemType) {
  return (
    <Style.LeftContentItem>
      <PrescriptionItemHeader item={item} />

      <Blank appoint="Vertical" size={16} />

      <OcrResultTable
        tmId={item?.tm?.id}
        prescriptionId={item.id}
        pageType={pageType}
      />

      <Blank appoint="Vertical" size={20} />

      <PdfViewer link={item?.tm?.prescription[0].image} height="960px" />
    </Style.LeftContentItem>
  );
}

export default PrescriptionItem;
