import { Font, ToggleTab } from '@components/atoms';
import SvgIcon, { IconType } from '@components/atoms/images';
import { loadingSpinner } from '@components/atoms/lottie';
import { commonHooks } from '@hooks';
import usePharmacyAutoConfirmedModalHook from '@pages/wrapper/modals/PharmacyAutoConfirmModal/hooks';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getManagementMedicineList,
  getPharmacyAutoConfirmed,
  selectMedicineManagementList,
  selectPharmacyAutoConfirmed,
  selectTelepharmacyCount_finished_pickup,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import cloneDeep from 'lodash/cloneDeep';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { medicineManagementCategoryConfig } from '../managementMedicineTable/config/domain';
import { getManagementMedicineListCreatedAfterDate } from '../managementMedicineTableV2';
import * as Style from './index.style';

const registerMedicineCountConfigInit: Record<string, number> = {
  피나스테리드: 0,
  '먹는 여드름약': 0,
  두타스테리드: 0,
  '바르는 여드름약': 0,
  '다이어트 주사': 0,
};

function PharmacyAutoConfirmed() {
  const { openModal, setPharmacyAutoConfirmedModalType } =
    usePharmacyAutoConfirmedModalHook();
  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const managementMedicineList = useAppSelector(selectMedicineManagementList);
  const isPharmacyAutoConfirmed = useAppSelector(selectPharmacyAutoConfirmed);

  const telepharmacyCount = useAppSelector(
    selectTelepharmacyCount_finished_pickup,
  );

  const [limit, setLimit] = useState<number>(30);
  const [isLoading, setIsLoading] = useState(true);
  const [isPharmacyAutoConfirmedOn, setIsPharmacyAutoConfirmedOn] =
    useState(true);
  const [registerMedicineCountConfig, setRegisterMedicineCountConfig] =
    useState(registerMedicineCountConfigInit);
  const [filteredMedicineListByCreated, setFilteredMedicineListByCreated] =
    useState<GetManagedMedicineItemType[]>([]);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;

  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  const handleClickAutoConfirmedToggle = () => {
    setPharmacyAutoConfirmedModalType(isPharmacyAutoConfirmedOn ? 'off' : 'on');
    openModal();
  };

  useEffect(() => {
    if (isPharmacyAutoConfirmed !== undefined) {
      setIsPharmacyAutoConfirmedOn(isPharmacyAutoConfirmed.auto_confirmed);
    }
  }, [isPharmacyAutoConfirmed]);

  useEffect(() => {
    if (pharmacist && pharmacist.pharmacy_id) {
      dispatch(
        getPharmacyAutoConfirmed({
          id: pharmacist.pharmacy_id || 0,
        }),
      );
      dispatch(
        getManagementMedicineList({
          id: pharmacist.pharmacy_id,
          offset: page * limit,
          limit,
        }),
      ).then(() => {
        setIsLoading(false);
      });
    }

    setIsLoading(true);

    return () => setIsLoading(false);
  }, [dispatch, page, limit, pharmacist]);

  const isNo = [1];

  useEffect(() => {
    if (managementMedicineList) {
      setFilteredMedicineListByCreated(
        managementMedicineList.filter((managementMedicine) =>
          getManagementMedicineListCreatedAfterDate(managementMedicine),
        ),
      );
    }
  }, [managementMedicineList]);

  useEffect(() => {
    if (filteredMedicineListByCreated) {
      const registerMedicineCountConfigResult = cloneDeep(
        registerMedicineCountConfigInit,
      );

      filteredMedicineListByCreated.forEach((item) => {
        const medicineName =
          medicineManagementCategoryConfig[
            item.pharmacy_medicine.generic_name_code
          ];

        if (item.pharmacy_medicine.is_active) {
          registerMedicineCountConfigResult[medicineName] += 1;
        }
      });

      setRegisterMedicineCountConfig(registerMedicineCountConfigResult);
    }
  }, [filteredMedicineListByCreated]);

  return (
    <Style.Container>
      <Style.ListArea>
        <Style.HeaderArea>
          <Style.TitleArea>
            <Font fontType="display2" color="fill/dark">
              비급여 조제 자동수락
            </Font>
          </Style.TitleArea>
          <ToggleTab
            isActive={isPharmacyAutoConfirmedOn}
            onClickToggle={handleClickAutoConfirmedToggle}
          />
        </Style.HeaderArea>

        <Style.ContentArea>
          {isLoading ? (
            <Style.NotYetTextArea>
              <Lottie
                animationData={loadingSpinner}
                style={{ width: 48, aspectRatio: 1 }}
                loop
              />
            </Style.NotYetTextArea>
          ) : (
            <>
              {isNo.length === 0 ? (
                <Style.NotYetTextArea>
                  <Font fontType="body1" color="fill/medium">
                    의약품 정보를 등록해야 이 기능을 사용할 수 있어요
                  </Font>
                </Style.NotYetTextArea>
              ) : (
                <Style.AutoConfirmedMedicineContainer>
                  <Style.AutoConfirmedMedicineRowContainer>
                    <AutoConfirmedMedicine
                      isPharmacyAutoConfirmedOn={isPharmacyAutoConfirmedOn}
                      iconName="pinkPillIcon"
                      medicineName="피나스테리드"
                      registerMedicineCount={
                        registerMedicineCountConfig['피나스테리드']
                      }
                    />
                    <AutoConfirmedMedicine
                      isPharmacyAutoConfirmedOn={isPharmacyAutoConfirmedOn}
                      iconName="yellowPillIcon"
                      medicineName="먹는 여드름약"
                      registerMedicineCount={
                        registerMedicineCountConfig['먹는 여드름약']
                      }
                    />
                  </Style.AutoConfirmedMedicineRowContainer>
                  <Style.Divider />
                  <Style.AutoConfirmedMedicineRowContainer>
                    <AutoConfirmedMedicine
                      isPharmacyAutoConfirmedOn={isPharmacyAutoConfirmedOn}
                      iconName="drugIcon"
                      medicineName="두타스테리드"
                      registerMedicineCount={
                        registerMedicineCountConfig['두타스테리드']
                      }
                    />
                    <AutoConfirmedMedicine
                      isPharmacyAutoConfirmedOn={isPharmacyAutoConfirmedOn}
                      iconName="medicineIcon"
                      medicineName="바르는 여드름약"
                      registerMedicineCount={
                        registerMedicineCountConfig['바르는 여드름약']
                      }
                    />
                  </Style.AutoConfirmedMedicineRowContainer>
                  <Style.Divider />
                  <Style.AutoConfirmedMedicineRowContainer>
                    <AutoConfirmedMedicine
                      isPharmacyAutoConfirmedOn={isPharmacyAutoConfirmedOn}
                      iconName="shotIcon"
                      medicineName="다이어트 주사"
                      registerMedicineCount={
                        registerMedicineCountConfig['다이어트 주사']
                      }
                    />
                  </Style.AutoConfirmedMedicineRowContainer>
                </Style.AutoConfirmedMedicineContainer>
              )}
            </>
          )}
        </Style.ContentArea>
      </Style.ListArea>
    </Style.Container>
  );
}

interface AutoConfirmedMedicineProps {
  isPharmacyAutoConfirmedOn: boolean;

  iconName: IconType;
  medicineName: string;
  registerMedicineCount: number;
}

function AutoConfirmedMedicine({
  isPharmacyAutoConfirmedOn,

  iconName,
  medicineName,
  registerMedicineCount,
}: AutoConfirmedMedicineProps) {
  const isMedicineRegistered = registerMedicineCount > 0;

  return (
    <Style.AutoConfirmedMedicineArea>
      <Style.AutoConfirmedMedicineTitleArea>
        <SvgIcon icon={iconName} width={18} height={18} color="fill/dark" />
        <Font fontType="body2_medium" color="fill/black">
          {medicineName}
        </Font>
      </Style.AutoConfirmedMedicineTitleArea>

      {isMedicineRegistered ? (
        <>
          {isPharmacyAutoConfirmedOn ? (
            <Font fontType="body2" color="blue500">
              {registerMedicineCount}개 약품 자동수락 중
            </Font>
          ) : (
            <Font fontType="body2" color="fill/medium">
              {registerMedicineCount}개 약품 대기 중
            </Font>
          )}
        </>
      ) : (
        <Font fontType="body2" color="red500">
          의약품 등록 필요
        </Font>
      )}
    </Style.AutoConfirmedMedicineArea>
  );
}

export default PharmacyAutoConfirmed;
