import SvgIcon from '@components/atoms/images';
import { toast } from 'react-toastify';
import * as Style from './index.style';

interface NotificationToastProps {
  icon: JSX.Element;
  title: JSX.Element;
  description: JSX.Element;
  button: JSX.Element;
}

function NotificationToastUI({
  icon,
  title,
  description,
  button,
}: NotificationToastProps) {
  return (
    <Style.NotificationToastArea>
      {icon}
      <Style.NotificationContentArea>
        <Style.NotificationTextArea>
          {title}
          {description}
        </Style.NotificationTextArea>
        {button}
      </Style.NotificationContentArea>
      <Style.CloseButtonArea
        onClick={() => {
          toast.dismiss();
        }}
      >
        <SvgIcon icon="closeIcon" width={20} color="fill/medium" />
      </Style.CloseButtonArea>
    </Style.NotificationToastArea>
  );
}

export default NotificationToastUI;
