import { SPACING_VARIABLES } from '@constants/styleVariables';
import styled from 'styled-components';

export const Container = styled.div<{ isVersionUp: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  margin-top: ${({ isVersionUp }) =>
    isVersionUp ? SPACING_VARIABLES.updateNudge : 0};
  margin-bottom: 40px;
`;

export const CardArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;
