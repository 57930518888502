import { Blank, Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import PrescriptionItem from '@components/organisms/telepharmacyDetailInformation/sections/prescriptionItem';
import { styleVariables } from '@constants';
import { commonHooks } from '@hooks';
import useFinishTelepharmacyModalHook from '@pages/wrapper/modals/finishTelepharmacyModal/hooks';
import { myDoctorAPI } from '@services';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { openModal_acceptTelepharmacyModal } from '@stores/modalStore/acceptTelepharmacyModal';
import { openModal_cancelTelepharmacyModal } from '@stores/modalStore/cancelTelepharmacyModal';
import { openModal_finishTelepharmacyModal } from '@stores/modalStore/finishTelepharmacyModal';
import { openModal_rejectTelepharmacyModal } from '@stores/modalStore/rejectTelepharmacyModal';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import fetchAPI from '@utils/fetchAPI';
import { trackConfirmedTreatment } from '@utils/mixpanel/confirmedTreatment/confirmed';
import { trackNewReservationAccepted } from '@utils/mixpanel/newReservation/accepted';
import { trackNewReservationRequested } from '@utils/mixpanel/newReservation/requested';
import { trackWaitForDispense } from '@utils/mixpanel/waitForDispense';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDeliveryMethod, getPatientName } from '../utils/domain';
import { TelepharmacyItemType } from './index.d';
import * as Style from './index.style';
import {
  HeaderLeftPartItem,
  HeaderRightPartItem,
  HospitalInformationPartItem,
  ProgressInformationPartItem,
  ReservationInformationPartItem,
} from './sections';

const { BREAK_POINTS } = styleVariables;

export const NO_PASSWORD_CASE_TEXT = '비밀번호 없이 출입 가능해요';

export type PageStatus =
  | 'requested'
  | 'accepted'
  | 'confirmed'
  | 'completed'
  | 'ongoing_quick'
  | 'ongoing_parcel'
  | 'pick_up';

function TelepharmacyDetailInformation({ pageType }: { pageType: PageStatus }) {
  const { finishTelepharmacy } = useFinishTelepharmacyModalHook();
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);

  const { useQuery, useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const telepharmacyID = query.get('id');
  const [item, setItem] = useState<TelepharmacyItemType>({});

  const { deliveryType, iconURL } = getDeliveryMethod({ item });

  const patientName = getPatientName({ item });

  // tp 데이터 fetch
  useEffect(() => {
    async function fetchData() {
      const data = await myDoctorAPI.getTelepharmacyItem(
        {},
        Number(telepharmacyID),
      );
      setItem(data);
    }

    if (telepharmacyID && Number(telepharmacyID) > 0) {
      fetchData();
    }

    return () => setItem({});
  }, [telepharmacyID]);

  const rejectTelepharmacy = () => {
    if (item.id) {
      dispatch(
        openModal_rejectTelepharmacyModal({
          item,
          isVisible: false,
        }),
      );
    }
  };

  const acceptTelepharmacy = () => {
    dispatch(openModal_acceptTelepharmacyModal({ item }));
  };

  const cancelTelepharmacy = () => {
    dispatch(
      openModal_cancelTelepharmacyModal({
        item,
      }),
    );
  };

  const requestTelepharmacy = () => {
    if (deliveryType === '방문') {
      finishTelepharmacy({ isPickup: true, id: item.id });
      return;
    }

    dispatch(
      openModal_finishTelepharmacyModal({
        item: {
          id: item.id,
          patientName,
          method: item.method,
          patientPhone: item.tm?.phone,
          patientAddress: item.address_string,
          addressMemo: item.address_memo,
          address_entrance_password:
            item.address_entrance_password || NO_PASSWORD_CASE_TEXT,
          pharmacy_request_detail: item.pharmacy_request_detail,
          pharmacy_product_payment: item.pharmacy_product_payment,
        },
      }),
    );
  };

  const visitTelepharmacy = () => {
    fetchAPI
      .post(
        `pharmacist-api/telepharmacy/tpprocess/${item.id}/pickup-finish/`,
        {},
        'myDoctor',
      )
      .then((response) => {
        if (response.status === 200) {
          alert('방문 완료되었습니다.');
          navigate('/home/confirmedTreatment/pick-up');
        }
      });
  };

  useEffect(() => {
    if (item && item.tm && pharmacist.pharmacy_id) {
      if (pageType === 'accepted') {
        trackNewReservationAccepted.pageNewReservationAcceptedDetail({
          tppId: telepharmacyID || '',
          phId: String(pharmacist.pharmacy_id),
          tmId: String(item.tm.id),
          deliveryMethod: getDeliveryMethod({ item }).deliveryType,
          symptomKeyword:
            item.tm?.symptom_keyword_history[0]?.symptom_keyword.name,
          tmGuideType: item?.tm.telemedicine_proof?.proof_user_type || '',
        });
      } else if (pageType === 'pick_up') {
        trackConfirmedTreatment.pageDeliveryRequestedDetail({
          tppId: telepharmacyID || '',
          phId: String(pharmacist.pharmacy_id),
          tmId: String(item.tm.id),
          deliveryMethod: getDeliveryMethod({ item }).deliveryType,
          symptomKeyword:
            item.tm?.symptom_keyword_history[0]?.symptom_keyword.name,
          tmGuideType: item?.tm.telemedicine_proof?.proof_user_type || '',
        });
      } else if (pageType === 'confirmed') {
        trackWaitForDispense.pageTPConfirmedDetail({
          tppId: telepharmacyID || '',
          phId: String(pharmacist.pharmacy_id),
          tmId: String(item.tm.id),
          deliveryMethod: getDeliveryMethod({ item }).deliveryType,
          symptomKeyword:
            item.tm?.symptom_keyword_history[0]?.symptom_keyword.name,
          tmGuideType: item?.tm.telemedicine_proof?.proof_user_type || '',
        });
      } else if (pageType === 'requested') {
        trackNewReservationRequested.pageTPRequested({
          tppId: telepharmacyID || '',
          pharmacyId: String(pharmacist.pharmacy_id),
          deliveryMethod: getDeliveryMethod({ item }).deliveryType,
          symptomKeyword:
            item.tm?.symptom_keyword_history[0]?.symptom_keyword.name,
          tmGuideType: item?.tm.telemedicine_proof?.proof_user_type || '',
        });
      }
    }
  }, [item, pageType, pharmacist, telepharmacyID]);

  // 페이지별 달라지는 요소들
  let pageHeaderButton: JSX.Element | null = null;

  switch (pageType) {
    case 'requested':
      pageHeaderButton = (
        <>
          <Style.HeaderButtonArea>
            <Button
              padding="16px"
              backgroundColor={COLOR['fill/dark']}
              borderRadius="8px"
              title={
                <Font fontType="body1_medium" color="fill/white">
                  조제 요청 거절
                </Font>
              }
              onClick={rejectTelepharmacy}
            />
          </Style.HeaderButtonArea>
          <Style.HeaderButtonArea>
            <Button
              padding="16px"
              backgroundColor={COLOR['fill/accent']}
              borderRadius="8px"
              title={
                <Style.ButtonContainer>
                  <SvgIcon
                    width={20}
                    height={20}
                    icon="checkCircleIcon"
                    color="fill/white"
                  />
                  <Blank appoint="Horizontal" size={8} />
                  <Font fontType="body1_medium" color="fill/white">
                    수락 및 가격 입력
                  </Font>
                </Style.ButtonContainer>
              }
              onClick={acceptTelepharmacy}
            />
          </Style.HeaderButtonArea>
        </>
      );
      break;
    case 'accepted':
      pageHeaderButton = null;
      break;
    case 'completed':
      pageHeaderButton = null;
      break;
    case 'confirmed':
      pageHeaderButton = (
        <>
          <Style.HeaderButtonArea>
            <Button
              padding="16px"
              backgroundColor={COLOR['fill/dark']}
              borderRadius="8px"
              title={
                <Style.ButtonContainer>
                  <Font fontType="body1_medium" color="fill/white">
                    조제 취소
                  </Font>
                </Style.ButtonContainer>
              }
              onClick={cancelTelepharmacy}
            />
          </Style.HeaderButtonArea>
          <Style.HeaderButtonArea>
            <Button
              padding="16px"
              backgroundColor={COLOR['fill/accent']}
              borderRadius="8px"
              title={
                <Style.ButtonContainer>
                  <SvgIcon
                    width={20}
                    height={20}
                    icon="checkCircleIcon"
                    color="fill/white"
                  />
                  <Blank appoint="Horizontal" size={8} />
                  <Font fontType="body1_medium" color="fill/white">
                    약국 방문 요청
                  </Font>
                </Style.ButtonContainer>
              }
              onClick={requestTelepharmacy}
            />
          </Style.HeaderButtonArea>
        </>
      );
      break;
    case 'ongoing_quick':
      pageHeaderButton = null;
      break;
    case 'ongoing_parcel':
      pageHeaderButton = null;
      break;
    case 'pick_up':
      pageHeaderButton = (
        <Style.HeaderButtonArea>
          <Button
            padding="16px"
            backgroundColor={COLOR['fill/accent']}
            borderRadius="8px"
            title={
              <Style.ButtonContainer>
                <SvgIcon
                  width={20}
                  height={20}
                  icon="checkCircleIcon"
                  color="fill/white"
                />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body1_medium" color="fill/white">
                  방문 완료
                </Font>
              </Style.ButtonContainer>
            }
            onClick={visitTelepharmacy}
          />
        </Style.HeaderButtonArea>
      );
      break;
    default:
      break;
  }

  if (item.id) {
    return (
      <Style.Container>
        <Style.Header>
          <Style.HeaderLeft>
            <HeaderLeftPartItem
              patientName={patientName}
              phoneNum={item.tm?.phone}
            />
          </Style.HeaderLeft>
          <Style.HeaderRight>
            <HeaderRightPartItem pageHeaderButton={pageHeaderButton} />
          </Style.HeaderRight>
        </Style.Header>
        <Style.Content
          className={
            Number(windowWidth) < BREAK_POINTS.size1440 ? 'smallContent' : ''
          }
        >
          <Style.LeftContent>
            <PrescriptionItem item={item} pageType={pageType} />
          </Style.LeftContent>
          <Style.RightContent>
            <ProgressInformationPartItem
              item={item}
              deliveryType={deliveryType}
              iconURL={iconURL}
            />
            <ReservationInformationPartItem
              item={item}
              iconURL={iconURL}
              status={pageType}
            />
            <HospitalInformationPartItem item={item} />
          </Style.RightContent>
        </Style.Content>
      </Style.Container>
    );
  }
  return null;
}

export default TelepharmacyDetailInformation;
