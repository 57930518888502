import { ReactBootstrapIcons } from '@assets';
import { COLOR, Font } from '@components/atoms';
import { useAppSelector } from '@stores/hooks';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { trackNewReservationRequested } from '@utils/mixpanel/newReservation/requested';
import { useEffect } from 'react';
import { EmptyArea, EmptyFontArea, EmptyIconArea } from './index.style';

function NoData({ title }: { title: string }) {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);

  useEffect(() => {
    if (!pharmacist.pharmacy_id) return;

    trackNewReservationRequested.pageTPRequested({
      tppId: null,
      pharmacyId: String(pharmacist.pharmacy_id),
      deliveryMethod: null,
      symptomKeyword: null,
      tmGuideType: null,
    });
  }, [pharmacist.pharmacy_id]);

  return (
    <EmptyArea>
      <EmptyIconArea>
        <ReactBootstrapIcons.Clipboard size={32} color={COLOR['fill/dark']} />
      </EmptyIconArea>
      <EmptyFontArea>
        <Font fontType="h1" color="fill/dark">
          {title}
        </Font>
      </EmptyFontArea>
    </EmptyArea>
  );
}

export default NoData;
